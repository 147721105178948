import counterUp from 'counterup2';
require( 'waypoints/lib/noframework.waypoints.js' );

/**
 * Count up numbers
 * @param element
 * @param duration
 * @return counterUp
 */
window.countUp = function countUp(elements, duration) {
    for (let i = 0; i < elements.length; i++) {
        new Waypoint( {
            element: elements[i],
            handler: function() {
                counterUp( elements[i], {
                    duration: duration,
                    delay: 10,
                } );
                this.destroy();
            },
            offset: 'bottom-in-view',
        } );
    }
};
